/*
 * @Author: princemwang
 * @Date: 2022-06-22 15:05:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-23 12:31:09
 */
import logoImg from '@/assets/imgs/tencent_logo.jpeg';
import { createInitFormText } from '@/globalState';
import { isMobile } from 'react-device-detect';
import titleLogo from '@/assets/imgs/titleLogo.png';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import config from '../../../config';
import './style.css';

const GroupHeader = () => {
  const [formText] = createInitFormText();
  const location = useLocation();
  const [pathName, setPathName] = useState(location.pathname);
  const livePageShow = useMemo(() => {
    return !isMobile || (pathName !== `/${config.webcastId}` && isMobile);
  }, [pathName]);
  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);
  return (
    <div id="header-content">
      <div className="webcast-header">
        <div className="webcast-log" style={{ lineHeight: '0px' }}>
          <img src={logoImg} className="logo-img" />
        </div>
        <div className="web-nav">
          <a href="https://www.tencent.com/en-us/investors.html" className="web-home-btn" rel="noreferrer">
            Home
          </a>
        </div>
      </div>
      {livePageShow && (
        <div className="webcast-info">
          <div className="info-left">
            <span>{formText.title}</span>
          </div>
          {!isMobile && (
            <div className="info-right">
              <img src={titleLogo} alt="tencnet cloud" />
              <span className="info-right__line"></span>
              <span className="info-right__text">Powered by Tencent Cloud Media Services：TRTC_helper@tencent.com</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default GroupHeader;
